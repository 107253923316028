@import "../../styles/utils.scss";
$time: 400ms;

#ResumeSwipeRoot {
  position: relative;
  transform-origin: top left;
  transition: all $time;
}

#ResumeSwipeNext {
  position: absolute;
  top: 0;
  left: 0;
}

/* This fires as soon as the element enters the dom */
.Swipe-enter,
.Swipe-appear {
  /*We give the list the initial dimension of the list button*/
  width: 0;
  opacity: 0;
  overflow: hidden;
  & #ResumeSwipeNext {
    position: absolute;
  }
}
/* This is where we can add the transition*/
.Swipe-enter-active,
.Swipe-appear-active {
  opacity: 1;
  width: 804px;
  overflow: hidden;
  transition: all $time;
  & #ResumeSwipeNext {
    position: absolute;
  }
}

.Swipe-exit {
  opacity: 1;
  width: 804px;
  overflow: hidden;
  & #ResumeSwipeNext {
    position: absolute;
  }
}
/* fires as element leaves the DOM*/
.Swipe-exit-active {
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: all $time;
  & #ResumeSwipeNext {
    position: absolute;
  }
}

.Swipe-exit-done {
  opacity: 1;
  width: 804px;
  overflow: visible;
  & #ResumeSwipeNext {
    position: relative;
  }
}







.Translate {
  margin: 0 auto
}
.Translate-exit-done {
  padding: 20px 0 20px;
}
// Before transition to "enter"
.Translate-enter,
.Translate-appear {
  padding: 20px 0 20px;
}
// Initiates transition to "enter"
.Translate-enter-active,
.Translate-appear-active {
  padding: 0;
  transition: padding $time;
}

// After transition to "enter"
.Translate-enter-done {
  padding: 0;
}
// Before transition to "exit"
.Translate-exit {
  padding: 0;
}
// Initiates transition to "exit"
.Translate-exit-active {
  padding: 20px 0 20px;
  transition: padding $time;
}
