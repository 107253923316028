@import "../../styles/utils.scss";
$dark: #282c34;
$info: #888;
$medium: #aaa;
$light: #ccc;
$color: #06c;
$arrow: 5px;
$time: 400ms;
$font-size: 8px;

.Menu {
  background: $light;
  color: $dark;
  widows: 100%;
  height: 100%;
  display: block;
  font-size: calc(5px + 2vmin);
  overflow: auto;
  padding: 62px 0 56px;
  transition: $time ease padding; }

.MenuBody {
  line-height: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.MenuClose {
  color: rgb(206, 24, 24);
  position: absolute;
  right: 0;
  top: 50%;
  @include translate(0, -50%);
  font-size: 80%;
  cursor: pointer; }

.MenuContent {
  background: $light; }

.MenuEscape {
  font-size: calc((10px + 2vmin) * 0.85);
  position: absolute;
  right: calc(100% + 7px);
  top: 50vh;
  transition: all $time;
  & > :first-child {
    position: absolute;
    top: calc(50% - (5px * 1.414 / 2));
    right: -$arrow;
    @include rotate(135);
    width: 0;
    height: 0;
    border: $arrow solid #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    z-index: 1; }
  & > :last-child {
    background: $color;
    border: 2px solid #fff;
    color: #fff;
    cursor: pointer;
    padding: 6px;
    white-space: nowrap;
    & > span {
      color: $light;
      font-style: italic; } } }

.MenuEscapeOpen {
  @include translate(0%, -50%);
  transition: all $time; }
.MenuEscapeClose {
  @include translate(calc(100% + 5px), -50%);
  transition: all $time; }

.MenuHeader {
  width: 100%;
  text-align: center;
  font-size: 120%;
  padding: 10px;
  border-bottom: 1px solid #fff;
  @include box-shadow(0, 0, 2px, #fff);
  background-color: #282c34;
  color: white; }

.MenuInfo {
  color: $info;
  font-size: 80%;
  font-style: italic;
  text-align: center; }

.MenuInput {
  color: $info;
  font-size: 80%;
  margin-top: 10px;
  margin-left: 20px;
  text-align: center; }

.MenuItem {
  margin-left: 30px;
  width: calc(100% - 40px);
  & input[type="number"] {
    width: 4em; } }

.MenuSubcontent {
  background: $light;
  margin-top: 3px;
  margin-bottom: 6px;
  padding-top: 10px;
  padding-right: 3px;
  overflow: hidden;
  & > div {
    background: #fff;
    border: 1px solid $medium;
    @include box-shadow(0, 0px, 3px, $medium);
    color: $dark;
    margin-top: 5px;
    padding: 4px; }
  & > div > span {
    margin-left: 4px; }
  & label {
    display: block;
    padding: 2px;
    margin: 2px 0; }
  & input[type="checkbox"], input[type="radio"] {
    margin-right: 7px; } }

.MenuSubheader {
  background-color: $light;
  color: $dark;
  font-weight: bold;
  margin-top: 6px;
  margin-left: -15px;
  margin-bottom: -7px;
  padding: 5px 5px 0; }
