@import './utils.scss';
$time: 400ms;

.fade-enter {
    opacity: 0 !important;
    z-index: 0;
}
.fade-enter-active,
.fade-enter-done {
    opacity: 1 !important;
    z-index: 1;
    transition: $time ease all;
}
.fade-exit,
.fade-exit-done,
.fade-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition: $time ease all;
}