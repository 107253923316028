@import "../../styles/utils.scss";
$time: 400ms;
$dark: #06c;
$arrow: 5px;

.TunerRO {
  width: 100%; }

.Tuner {
  cursor: pointer;
  position: relative;
  width: 100%;
  & > .TunerContent:hover {
    @include box-shadow(0, 0, 5px, $dark);
    z-index: 1; }
  & > .TunerRestore {
    color: #fff;
    opacity: 0;
    position: absolute;
    top: 50%;
    @include translate(-100%, -50%);
    left: 0;
    width: 0;
    z-index: 2;
    & > :first-child > :first-child {
        position: absolute;
        top: calc(50% - (5px * 1.414 / 2));
        left: -$arrow;
        @include translate(0, -50%);
        @include rotate(-45);
        width: 0;
        height: 0;
        border: $arrow solid #000;
        border-right-color: transparent;
        border-bottom-color: transparent;
        z-index: 1; }
    & > :first-child > :nth-child(2) {
        background: $dark;
        border: 2px solid #000;
        padding: 0 3px;
        z-index: 2; } } }

@mixin tuner($content-visible, $transitions) {
  & > .TunerContent {
    opacity: if($content-visible, 1, 0.2);
    @if $transitions {
      transition: all $time; } }

  & > .TunerRestore {
    opacity: if($content-visible, 0, 1);
    left: if($content-visible, 0, calc(100% + 5px));
    @if $transitions {
      transition: all $time; } }

  > .TunerRestore > * {
    opacity: if($content-visible, 0, 1);
    left: if($content-visible, 0, calc(100% + 5px));
    width: if($content-visible, 0, 80px);
    @if $transitions {
      transition: all $time; } } }

// Before transition to "enter" (hidden)
.Tuner-exit-done,
.Tuner-enter,
.Tuner-appear {
  @include tuner(false, false); }

// Initiates transition to "enter"
.Tuner-enter-active,
.Tuner-appear-active {
  @include tuner (true, true); }

// After transition to "enter"
// Before transition to "exit"
.Tuner-enter-done,
.Tuner-exit {
  @include tuner(true, false); }

// Initiates transition to "exit" (not hidden)
.Tuner-exit-active {
  @include tuner(false, true); }
