@import "../../styles/utils.scss";

.Company {
    @include border-radius(5px);
    background: #b8cbe0;
    border: 1px solid #000c;
    color: #000;
    display: inline-block;
    font-size: 0.8em;
    padding: 0 0.5em;
}

.KeyPoint {
    float: left;
    font-size: 140%;
    padding: 1rem !important;
}

.Language {
    @include border-radius(5px);
    background: #e1e1e1cc;
    border: 1px solid #aaac;
    display: inline-block;
    font-size: 0.8em;
    padding: 0 0.5em;
}

.LanguagesContainer {
    position: absolute;
    left: 5px;
    bottom: 5px;
}

.ProjectsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    margin: 0 auto;
    width: 20rem;
    @media screen and (min-width: 40rem) {
        width: 40rem;
    }
}

.Role { 
    @include border-radius(5px);
    background: #06cc;
    border: 1px solid #000c;
    color: #fff;
    display: inline-block;
    font-size: 0.8em;
    padding: 0 0.5em;
}

.RolesContainer {
    position: absolute;
    top: 5px;
    left: 5px;
}