@import "./utils.scss";
$light-color: #b8cbe0;

@mixin SocialIcon($column, $row, $scale: 0.70) {
    $size: 68px;
    $scaled-size: floor($size * $scale - 1px);
    height: $scaled-size;
    margin-left: 1em;
    overflow: hidden;
    position: relative;
    width: $scaled-size;
    & > :first-child {
        position: absolute;
        top: -(($size / 2) - ($scaled-size / 2));
        left: -(($size / 2) - ($scaled-size / 2));
        @include scale($scale);
        background: url(../images/social-media-icon-inverse.png);
        background-position: (-26px - ($column - 1) * 120px) (-26px - ($row - 1) * 120px);
        height: $size;
        width: $size;
        z-index: 2;
        &:hover + div {
            background-color: $light-color;
        } 
    }
    & > :last-child {
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: 200ms ease background-color;
    }
}

.GitHub {
    @include SocialIcon(2, 1)
}

.LinkedIn {
    @include SocialIcon(1, 2)
}