
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate ($deg) { 
	@include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

@mixin box-shadow($left: 0, $top: 0, $blur: 0, $color: inherit) {
	$value: $left $top $blur $color;
	-khtml-box-shadow: $value;
	-moz-box-shadow: $value;
	-ms-box-shadow: $value;
	-o-box-shadow: $value;
	box-shadow: $value;
}

@mixin border-radius($radius) {
	-khtml-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}