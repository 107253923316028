@import "../../styles/utils.scss";
$dark: #06c;
$medium: #999;
$light: #ccc;
$lighter: #d9d9d9;


.Body {
  font-family: Calibri;
  font-size: 12pt;
  //margin: 20px auto 30vh;
  max-width: 7.7in;
  line-height: 120%;
  margin: 0 auto;
}
.Block {
  margin: 2px;
  position: relative;
  max-width: 7.7in;
}
.Bullet {
  margin-bottom: 0;
  margin-top: 0;
  padding-inline-start: 30px;
}
.Center {
  text-align: center;
}
.Container {
  padding: 2px;
}
.ExternalLink {
  font-weight: bold;
  text-transform: none !important;
  & a {
    color: $dark;
    font-weight: normal;
    padding: 0 3px;
    text-decoration: none;
    & ::active, ::hover, ::visited {
      color: $dark
    }
  }
}
.FlexColumn {
  display: flex;
}
.FlexLastColumn {
  display: flex;
  flex: 1;
}
.FlexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
@mixin GradientBackground($first, $second, $third: null, $direction: to right) {
  @if ($third) {
	background-image: linear-gradient($direction, $first, $second, $third);
  }
  @else {
	background-image: linear-gradient($direction, $first, $second);
  }
}
.Header1 {
  font-weight: bold;
  margin-top: 3px;
  text-transform: uppercase;
}
.Header2 {
  font-weight: bold;
  margin-top: 4px;
  padding: 0 1px;
  text-transform: uppercase;
}
.Header3 {
  font-style: italic;
}
.Hidden {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
}
.Highlight {
  color: $dark;
}
.HorizontalDivider {
  width: 100%;
  height: 0px;
}
.HorizontalDivider1 {
  @extend .HorizontalDivider;
  border-bottom: 1px solid $dark;
  //@include GradientBackground($dark, $lighter);
}
.HorizontalDivider2 {
  @extend .HorizontalDivider;
  border-bottom: 1px solid $medium;
  //@include GradientBackground($lighter, $medium, $lighter);
}
.HorizontalDivider3 {
  @extend .HorizontalDivider2;
  //@include GradientBackground($lighter, $medium);
}
.InlineDivider {
  font-size: 80%;
  color: $dark;
  font-weight: bold;
  width: 50px;
}
.InnerBlock {
  margin: 2px 0;
}
.Left {
  text-align: left;
}
@mixin List($style-type) {
	list-style-type: $style-type;
	li { 
		white-space: nowrap;
	}
}
.List0 {
  @include List(circle);
}
.List1 {
  @include List(disc);
}
.Latin {
  font-style: italic;
  font-family: serif;
  text-transform: lowercase;
}
.Nowrap {
  white-space: nowrap;
}
.Right {
  text-align: right;
}
.Skill {
  display: flex;
  z-index: 5;
}
.SkillDivider {
  background: #fff;
  display: flex;
  font-weight: bold;
  overflow: visible;
  width: 0;
}
.Subtitle {
  margin-top: 2px;
}
.SubtitleCompact {
  font-size: 90%;
  margin-bottom:4px;
}
.Title {
  font-size: 20pt;
  margin-bottom: 6px;
  text-align: center;
}
.Union {
  font-weight: bold;
  font-size: 80%;
  text-align: center;
}

