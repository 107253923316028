@import "./utils.scss";
@import url('bootstrap.min.css') screen;
$darker: #0056b3;
$dark: #06c;
$dark-gray: rgba(0, 0, 0, 0.7);
$medium: #aaa;
$light-color: #b8cbe0;
$nav-back: #f8f9fa;
$time: 400ms;

.App {
  margin-bottom: 50px;
  transition: $time ease all;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $dark;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HomeItem,
.HomeItemAppend {
  top: 0;
  left: 0;
  opacity: 0; 
  width: 100%;
}
.HomeItem-all {
  position: absolute;
  top: 50%;
  @include translate(0, -50%);
  overflow: hidden;
  width: 100%;
}
.HomeItem-left-enter {
  left: 100%;
}
.HomeItem-left-active {
  left: 0;
  transition: $time ease all;
}
.HomeItem-left-exit {
  left: -100%;
  transition: $time ease all;
}
.HomeItem-top-enter {
  top: 0;
  @include translate(0, 0);
  opacity: 0;
}
.HomeItem-top-active {
  opacity: 1;
  @include translate(0, 0);
  transition: ($time*2) ease all;
}
.HomeItem-exit {
  position: relative;
  width: 100%;
  overflow: visible;
}

.Page a {
  color: $darker!important;
  text-decoration: none!important;
  transition: 200ms ease text-shadow!important;
  &:hover {
    color: $dark-gray!important;
    text-decoration: none!important;
    text-shadow: 0 0 0.3em $light-color!important;
  }
  &:active,:visited {
    text-decoration: none!important;
    color: $darker!important;
  }
}

.Footer,
.Navigation {
  background: $nav-back;
  width: 100%;
  transition: $time ease all;
  & a {
    color: $medium;
    text-decoration: none;
    transition: 200ms ease text-shadow;
    &:hover {
      color: $dark-gray;
      text-decoration: none;
      text-shadow: 0 0 0.3em $light-color;
    }
    &:active,:visited {
      text-decoration: none;
      color: $medium;
    }
  }
}

.NavigationPlaceholder {
  height: 62px;
  transition: $time ease height;
}

.Page { 
  color: $dark-gray;
  position: relative;
  overflow: hidden;
  max-width: 7.7in;
  margin: 0 auto 5em;
  transition: $time ease all!important;
  z-index: 0;
}

.PageContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}



