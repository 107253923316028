
.ProjectImage {
    margin: 0 auto;
    padding: 10px 0;
    & > img {
        border: 1px solid #444;
        border-radius: 2px;
        max-width: 100%;
    }
    & > span {
        color: #777;
        font-size: 80%;
        font-style: italic;
    }
}

.SmallRightImage {
    & > img {
        width: 20em;
    }
    @media screen and (min-width: 35rem) {
        margin: 0.25rem 0 0 0;
        float: right;
        padding: 0 0 1rem 1rem;
    }
}

.ProjectMobile {
    display: block;
    @media screen and (min-width: 35rem) {
        display: none;
    }
}
.ProjectDesktop {
    display: none;
    @media screen and (min-width: 35rem) {
        display: block;
    }
}